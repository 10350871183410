/* Dropdown.css */
.dropdown-container-custom {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    // margin: 20px;
  
  
  select {
    padding: 8px;
    font-size: 11px !important;
    // margin-top: 0px; 
    // position: relative;
    // top: -20px; 
    // margin-left: -20px;
    width: 150px !important;
  }
  
  p {
    font-size: 18px;
    margin-top: 20px;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
  }
  
  .modal h3 {
    margin-bottom: 15px;
  }
  
  .modal-body {
    margin-bottom: 20px;
  }
  
  .modal-body input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between;
  }
  
  button {
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
  }
  
  .close-btn {
    background-color: #ccc;
  }
  
  .save-btn {
    background-color: #007bff;
    color: white;
  }
  
  button:hover {
    opacity: 0.8;
  }

  .disabled-dropdown option {
    color: gray;
    pointer-events: none;
    background-color: #f0f0f0;
  }

  .reminder {
    color: gray;
    font-size: 0.9em;
    margin-top: 10px;
  }
  
}